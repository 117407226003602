import { ThemeType } from 'src/common/enums';

const VectorLogo = undefined;

const defaultTheme = ThemeType.LIGHT;
const hasThemeSwitcher = false;
const hasNetworkSwitcher = true;
const hasWhatsappWidget = false;
const hasBanner = true;
const isDisableWalletLogin = false;

const headerLogoSize = 180;
const HeaderLogo =
  'https://cdn.nft-investment.io/c32343da-037c-464c-88cd-7907d7766a61/public/images/69d6267cb5f3864029ab7b64ebf2100a.jpg';
const FooterLogo = HeaderLogo;
const DarkHeaderLogo = HeaderLogo;
const DarkFooterLogo = FooterLogo;
const footerLogoSize = 180;

export {
  VectorLogo,
  HeaderLogo,
  DarkHeaderLogo,
  headerLogoSize,
  FooterLogo,
  DarkFooterLogo,
  footerLogoSize,
  defaultTheme,
  hasThemeSwitcher,
  hasNetworkSwitcher,
  hasWhatsappWidget,
  hasBanner,
  isDisableWalletLogin,
};
